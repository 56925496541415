@media screen and (min-width: 320px) and (max-width: 1080px) {
  #face {
    width: 100px;
    height: 100px;
  }

  body {
    margin-top: 35%;
  }

  #link {
    border-radius: 10px;
    backdrop-filter: blur(2px);
    background: #3b0f6434;
  }
}
@media screen and (min-width: 1080px) and (max-width: 2160px) {
  #face {
    height: 250px;
    width: 250px;
  }

  body {
    margin-top: 15%;
  }
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .react-pdf__Page__canvas {
    width: 300px !important;
    height: auto !important;
  }
}
#face {
  border-radius: 50%;
  margin: 12px;
  filter: url("#duotone_peachypink") blur(1px);
  background: url("./face.jpg");
  background-size: 100%;
  /*###Tablet(medium)###*/
}
#face:hover {
  animation: facein 0.5s;
  filter: none;
  box-shadow: 4px 4px 5px 1px rgba(95, 48, 149, 0.2);
  background-image: url("./face.jpg"), url("./shine.png");
  background-blend-mode: screen;
}

@keyframes facein {
  from {
    filter: blur(5px);
  }
  to {
    filter: blur(0px);
    filter: none;
    box-shadow: 4px 4px 5px 1px rgba(95, 48, 149, 0.2);
  }
}
.svg-filters {
  height: 0;
  left: -9999em;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 0;
}

#Rigth {
  background-color: #68686863;
  border: solid 1mm #000000;
  border-radius: 10px;
  margin-bottom: 50%;
  margin-left: 5%;
  margin-right: 5%;
  color: white;
  padding: 5px;
}

a {
  text-decoration: none;
}

nav {
  font-family: Montserrat, sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

li {
  color: #fff;
  display: block;
  float: left;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}
li a {
  color: #fff;
}
li:hover {
  cursor: pointer;
  color: #858585;
}
li:focus-within {
  cursor: pointer;
}
li:focus-within a {
  outline: none;
}

ul li ul {
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
  animation: fadein 0.5s;
  text-align: center;
}
ul li:hover > ul, ul li:focus-within > ul {
  visibility: visible;
  opacity: 1;
  display: block;
}
ul li ul:hover, ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}
ul li ul li {
  clear: both;
  width: 100%;
}

h3 {
  text-align: center;
  font-size: 1.3rem;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: #020079;
  text-decoration: none;
}

i {
  font-size: 0.75rem;
}